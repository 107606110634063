import React from "react"

const ArrowRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <title>Right arrow</title>
      <path d="M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z" />
    </svg>
  )
}

export default ArrowRight
